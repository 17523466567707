@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden;
}

iframe#webpack-dev-server-client-overlay{display:none!important}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.aria-expanded\:opacity-70[aria-expanded="true"] {
  opacity: 1 !important;
}

.ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.css-1t8x7v1 > .ps-menu-button {
  height: auto !important;
}

input[type="file" i] {
  position: relative;
}

input[type="file" i]::before {
  content: "+";
  width: 18px;
  height: 18px;
  font-size: 1.5rem;
  color: white;
  position: absolute;
  top: 8px;
  left: 100px;
  display: block;
}

.user-profile::before {
  display: none !important;
}

.text-foreground {
  color: black !important;
}

.active > div {
  background-color: #001e43;
  color: white;
  border-radius: 1rem;
  font-weight: 600;
  margin-top: 10px;
}

/* for mobile nav */
.active {
  @apply text-regalBlue opacity-100;
}

.mySwiper1 .swiper-slide {
  width: 100px !important;
  height: 100px !important;
}

.mySwiper2 .swiper-slide {
  /* width: 442px !important; */
  height: 400px !important;
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

@media screen and (max-width: 800px) {
  .mySwiper2 .swiper-slide {
    width: 100% !important;
  }
}

@layer components {
  .details-style {
    @apply flex mb-7 flex-wrap px-1 [&>div>p]:text-center [&>div>p:first-child]:text-sm [&>div>p:first-child]:font-semibold [&>div>p:first-child]:leading-6 [&>div>p:last-child]:text-xs sm:px-4;
  }
  .customer-details {
    @apply flex gap-6 mb-5 [&>div>p:first-child]:text-xs [&>div>p:first-child]:text-gray-500 [&>div>p:first-child]:font-bold [&>div>p:last-child]:text-[0.875rem];
  }

  .address-details {
    @apply text-xs text-gray-500 font-bold;
  }

  /* auth screens */
  .auth_link {
    @apply text-orange-400 font-bold hover:brightness-95;
  }

  .auth_link_2 {
    @apply text-blue-950 font-semibold hover:text-blue-500;
  }

  .password_eye_icon {
    @apply text-lg text-default-400 pointer-events-none;
  }

  .table_cell {
    @apply font-semibold capitalize sm:text-[15px];
  }

  .table_cell_sm {
    @apply text-tiny text-default-400 font-medium;
  }

  .dispute-text {
    @apply flex justify-between sm:justify-around text-center mt-3 [&>div>p:first-child]:text-[0.75rem] sm:[&>div>p:first-child]:text-[0.9rem] sm:[&>div>p:first-child]:text-base [&>div>p:first-child]:font-semibold [&>div>p:last-child]:text-[0.8rem];
  }

  .order-generation-text {
    @apply [&>p>span]:text-xs md:[&>p>span]:text-sm [&>p>span:first-child]:font-semibold;
  }
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
      /* Customize the scrollbar width */
      width: 6px;
    }

    .no-scrollbar::-webkit-scrollbar-track {
      /* Customize the scrollbar track */
      background: #f1f1f1;
    }

    .no-scrollbar::-webkit-scrollbar-thumb {
      /* Customize the scrollbar thumb */
      background: #888;
      border-radius: 5px;
    }
  }
}
